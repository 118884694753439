export const colors = [
  { name: "redopinion", title: "ROJO LA OPINIÓN", value: "#FF0000" },
  { name: "black", title: "NEGRO", value: "#000000" },
  { name: "greydarken", title: "GRIS OSCURO", value: "#303030" },
  { name: "grey", title: "GRIS MEDIO", value: "#6d6d6d" },
  { name: "greylight", title: "GRIS CLARO", value: "#bfbfbf" },
  { name: "singaleraprimary", title: "SIN GALERA primario", value: "#6d1c80" },
  {
    name: "singalerasecondary",
    title: "SIN GALERA secundario",
    value: "#e570ff",
  },
  { name: "loqueimporta", title: "LO QUE IMPORTA", value: "#e8ff06" },
  { name: "destacada", title: "DESTACADA", value: "#fff98f" },
  { name: "archivo", title: "ARCHIVO", value: "#ffe3ab" },
];

export const colorsalert = [
  { name: "light", title: "Default", value: `#bfbfbf` },
  { name: "success", title: "Suceso", value: "#118f63" },
  { name: "warning", title: "Alerta", value: "#e8ff06" },
  { name: "danger", title: "Peligro", value: "#FF0000" },
];
